import { useContext, useState, useRef, useEffect } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import useSpeechSynthesis from '../exports/useSpeechSynthesis';
import Loading from './Loading';

import '../../styles/common/Video.scss';

const TranscriptIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/transcript.svg`;
const VoiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voice.svg`;
const openIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/open.svg`;

const TranslateIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/translate.svg`;
const TranslateArrowIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/translateArrow.svg`;

const Video = (props) => {
  const { store } = useContext(Context);
  const { speak, speaking, pause, resume, cancel } = useSpeechSynthesis();

  const [showTranscript, setShowTranscript] = useState(false);
  const initTranscript = useRef(false);

  const [determineLanguage, setDetermineLanguage] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState('');

  const [showTranslate, setShowTranslate] = useState(false);
  const [translateFrom, setTranslateFrom] = useState('');
  const [translateTo, setTranslateTo] = useState('');
  const [translating, setTranslating] = useState(false);
  const [translation, setTranslation] = useState('');

  const [mp3Available, setMp3Available] = useState(false);

  const [videoSrc, setVideoSrc] = useState('');
  const videoElement = useRef(null);
  const audioElement = useRef(null);
  const audioFinished = useRef(false);

  const transcriptControl = () => {
    if (!initTranscript.current) {
      initTranscript.current = true;
    }
    setShowTranscript(!showTranscript);
  }

  const translateControl = async () => {
    if (!showTranslate) {
      setDetermineLanguage(true);

      try {
        let data = {
          string: props.transcript,
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId,
        }
        const url = `${constants.services.url.api}/authenticity/ai/translate/detect/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        setTranslating(false);
        setDetermineLanguage(false);
        setShowTranslate(true);

        if (response.ok) {
          if (responseData.status === 'Success') {
            setSourceLanguage(responseData.language);
            manageLanguage({
              target: {
                value: constants.services.translateLanguages.find(lang => lang.value === responseData.language).label
              }
            }, 'from');
          } else {
            setSourceLanguage('');
            translateFrom('');
          }
        }
      } catch (e) {
        setSourceLanguage('');
        setDetermineLanguage(false);
        setShowTranslate(true);
      }
    } else {
      setSourceLanguage('');
      setDetermineLanguage(false);
      setShowTranslate(false);
      setMp3Available(false);
      videoElement.current.volume = 1;
    }
  }

  const manageLanguage = async (event, type) => {
    const languageSearch = constants.services.translateLanguages.find(lang => lang.label === event.target.value);
    const langString = languageSearch.value;

    setTranslation('');
    setMp3Available(false);

    if (type === 'from') {
      if (translateTo === langString) setTranslateTo('');
      setTranslateFrom(langString);
    } else if (type === 'to') {
      setTranslateTo(langString);
      setTranslating(true);

      try {
        let data = {
          string: props.transcript,
          translateFrom: translateFrom.split('-')[0],
          translateTo: langString.split('-')[0],
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId,
          aid: props.setPoster.replace('.gif', '')
        }
        const url = `${constants.services.url.api}/authenticity/ai/translate/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        setTranslating(false);

        if (response.ok) {
          if (responseData.status === 'Success') {
            const translationString = responseData.translation.translatedText;

            if (translationString.length) {
              setTranslation(translationString);
              setMp3Available(responseData.audio === 'Success');

              // Show transcript if not open
              if (!showTranscript) transcriptControl();
              videoElement.current.pause();
              videoElement.current.currentTime = 0;
              videoElement.current.volume = 0;
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handlePlay = () => {
    if (!videoSrc.length) {
      setVideoSrc(constants.services.s3ToCloudfrontMedia(props.setSrc));
    }
    if (videoElement.current.currentTime === 0) {
      cancel();
    }
    audioFinished.current = false; // Reset audio finished flag on play
  }

  useEffect(() => {
    const handleVideoPause = () => {
      pause();
      if (mp3Available && audioElement.current) {
        audioElement.current.pause();
      }
    };

    const handleVideoPlay = () => {
      if (videoElement.current.currentTime === 0) {
        cancel();
      }
    };

    const handleVideoTimeUpdate = () => {
      // Play the audio when the video time is no longer 0
      if (videoElement.current.currentTime > 0 && !audioFinished.current) {
        if (mp3Available) {
          if (audioElement.current && audioElement.current.paused) {
            audioElement.current.play();
          }
        } else {
          if (!speaking && showTranslate && translateTo.length) {
            speak((showTranslate && translation.length) ? translation : props.transcript, translateTo);
          } else {
            resume();
          }
        }
      }
    };

    const handleAudioEnded = () => {
      audioFinished.current = true; // Mark audio as finished
    };

    const handleVideoSeeked = () => {
      // Sync the audio time with the video time
      if (mp3Available && audioElement.current) {
        audioElement.current.currentTime = videoElement.current.currentTime;
        audioFinished.current = false; // Reset audio finished flag on seek
      } else {
        cancel();
        if (showTranslate && translateTo.length) speak((showTranslate && translation.length) ? translation : props.transcript, translateTo);
      }
    };

    const videoEl = videoElement.current;
    const audioEl = audioElement.current;
    if (videoEl) {
      videoEl.addEventListener('play', handleVideoPlay);
      videoEl.addEventListener('pause', handleVideoPause);
      videoEl.addEventListener('seeked', handleVideoSeeked);
      videoEl.addEventListener('timeupdate', handleVideoTimeUpdate);
    }
    if (audioEl) {
      audioEl.addEventListener('ended', handleAudioEnded);
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener('play', handleVideoPlay);
        videoEl.removeEventListener('pause', handleVideoPause);
        videoEl.removeEventListener('seeked', handleVideoSeeked);
        videoEl.removeEventListener('timeupdate', handleVideoTimeUpdate);
      }
      if (audioEl) {
        audioEl.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, [speak, pause, resume, cancel, mp3Available, props.transcript]);

  useEffect(() => {
    const currentVideoElement = videoElement.current;

    const playVideo = () => {
      if (currentVideoElement) currentVideoElement.play();
    };

    if (currentVideoElement && videoSrc.length) currentVideoElement.addEventListener('loadeddata', playVideo);

    return () => {
      if (currentVideoElement) currentVideoElement.removeEventListener('loadeddata', playVideo);
    };
  }, [videoSrc]);

  return (
    <div
      className={`Video ${props.level} ${props.level === 'main' ? (showTranscript ? ' transcript' : ' noTranscript') : ''}`}
      style={props.width !== undefined && !constants.utils.isMobile() ? { width: `${props.width}px` } : {}}>
      <video
        className={`videoPlayer transcript${showTranscript ? 'Open' : ' Closed'}${props.type === 'voice' ? ' voice' : ''}${props.level === 'main' ? ' main' : ''}`}
        style={props.width !== undefined && !constants.utils.isMobile() ? { width: `${props.width}px` } : {}}
        ref={videoElement}
        src={(props.src !== undefined && props.src.length) ? props.src : videoSrc}
        poster={''}
        onPlay={(e) => props.onPlay !== undefined ? props.onPlay(e) : null}
        playsInline
        controls />

      {/* MP3 audio element for playback */}
      {mp3Available ?
        <audio ref={audioElement} src={`${constants.services.awsLocation(true)}/${props.setPoster.replace('.gif', '')}-${translateFrom.split('-')[0]}-${translateTo.split('-')[0]}.mp3`} /> :
        ''
      }

      <div className={`posterBackground${!videoSrc.length && props.setPoster !== undefined ? ' active' : ''}`}>
        <div
          className="posterImage"
          style={{ backgroundImage: `url(${props.setPoster !== undefined ? `${constants.services.awsLocation(true)}/${props.setPoster}` : ''})` }} />
        {props.type === 'voice' &&
          <img
            className="voiceIcon"
            src={VoiceIcon}
            alt="Voice Icon" />
        }
        <button
          className="overlayPlayButton"
          disabled={videoSrc.length && props.setPoster !== undefined}
          onClick={() => handlePlay()}>
          <div className="openImage">
            <img
              className="openIcon"
              src={openIcon}
              alt="Open video" />
          </div>
        </button>
      </div>

      {(props.type === 'voice' && videoSrc.length) ?
        <img
          className="voiceIcon"
          src={VoiceIcon}
          alt="Voice Icon" /> : 
        ''
      }

      {(store.language === 'en' && props.transcript !== undefined && props.transcript !== 0 && props.transcript !== '') &&
        <>
          {/* Transcript Button */}
          {(props.settings !== undefined && props.settings.transcript !== undefined && props.settings.transcript) &&
            <button
              className={`transcriptButton${showTranscript ? ' open' : ''}`}
              onClick={() => transcriptControl()}>
              <img
                className="transcriptIcon"
                src={TranscriptIcon}
                alt="Transcript" />
            </button>
          }

          {/* Speech Button */}
          {props.transcript !== undefined && props.transcript !== 0 && props.transcript !== '' && 
           (props.settings !== undefined && props.settings.translate !== undefined && props.settings.translate) &&
            <button
              className={`translateButton${showTranslate ? ' open' : ''}`}
              onClick={() => translateControl()}>
              {determineLanguage ?
                <Loading active={true} /> :
                <img
                  className="translateIcon"
                  src={TranslateIcon}
                  alt="Translate" />
              }
            </button>
          }

          {showTranslate &&
            <div className="translateWrapper">
              <div className="translateOption">
                <select
                  className="translateSelect"
                  onChange={(event) => manageLanguage(event, 'from')}
                  defaultValue={`${strings.default[store.language].Video.Select}...`}
                  value={translateFrom.length ? constants.services.translateLanguages.find(lang => lang.value === translateFrom).label : `${strings.default[store.language].Video.Select}...`}
                  disabled={translating}>
                  <option>
                    {`${strings.default[store.language].Video.Select}...`}
                  </option>
                  {sourceLanguage.length ?
                    <option>
                      {constants.services.translateLanguages.find(lang => lang.value === sourceLanguage).label}
                    </option> :
                    constants.services.translateLanguages.map((language, i) => (
                      <option key={i}>
                        {language.label}
                      </option>
                    ))
                  }
                </select>
              </div>

              {translating ?
                <div className="loaderWrapper">
                  <Loading active="true" />
                </div> :
                <img
                  className={`translateArrowIcon${translateFrom.length ? ` active${translateTo.length ? ' valid' : ''}` : ''}`}
                  src={TranslateArrowIcon}
                  alt="Translate to" />
              }

              <div className="translateOption">
                <select
                  className="translateSelect"
                  onChange={(event) => manageLanguage(event, 'to')}
                  defaultValue={`${strings.default[store.language].Video.Select}...`}
                  value={translateTo.length ? constants.services.translateLanguages.find(lang => lang.value === translateTo).label : `${strings.default[store.language].Video.Select}...`}
                  disabled={!translateFrom.length || translating}>
                  <option selection="">
                    {`${strings.default[store.language].Video.Select}...`}
                  </option>
                  {constants.services.translateLanguages.map((language, i) => {
                    if (language.value !== translateFrom) {
                      return <option key={i}>
                        {language.label}
                      </option>
                    } else {
                      return '';
                    }
                  })}
                </select>
              </div>
            </div>
          }

          <div className={`transcriptWrapper${showTranscript ? ' open' : initTranscript.current ? ' closed' : ''}`}>
            <div className="transcript">
              <div className="transcriptText">
                {translating ?
                  <Loading active="true" /> :
                  (showTranslate && translation.length) ? translation : props.transcript
                }
                <div className="transcriptNote">
                  {strings.default[store.language].Video.Transcripts}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default Video;
