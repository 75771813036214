import { useContext, useState, useRef } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from 'react-router-dom';

import Reaction from '../common/Reaction';
import Vote from '../common/Vote';
import Video from '../common/Video';
import Keywords from '../common/Keywords';
import Participant from '../common/Participant';
import Loading from '../common/Loading';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/ThreadView.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const DeleteIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete.svg`;
const VideoEntry = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/video-icon.svg`;
const TextEntry = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/text-entry.svg`;
const GreenCheckmark = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/green-checkmark.svg`;

const ThreadView = (props) => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const [views, setViews] = useState([]);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [textResponse, setTextResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);

  const bottomScroll = useRef(null);

  const sendMessage = (data) => {
    const statusCopy = {
      ...store.status,
      message: {
        type: data.type,
        text: data.text
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  };

  const closeThread = () => {
    props.setClosing(true);

    setTimeout(() => {
      props.setClosing(false)

      // Add to URL Route
      history.push(`/g/${store.status.community.cid}/${store.status.group.gid}/${store.status.space.sid}/`);

      // Clear from DataStore
      const statusCopy = {
        ...store.status,
        asset: {}
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }, 600);
  }

  const respondThread = () => {
    const magic = (store.status.space.magic !== undefined && store.status.space.magic);

    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'create',
        type: 'asset',
        store: 'assets',
        thread: store.status.asset.aid,
        data: {
          title: `${strings.default[store.language].AssetsView.SidebarWith} ${store.status.asset.creator.name.split(' ')[0]}`,
          inputTitle: strings.default[store.language].Modal.ShortDescription,
          placeholder: strings.default[store.language].AssetsView.ThreadDescriptionPlaceholder,
          privacySetting: false,
          magicAI: magic,
          creatorStudio: true,
          response: true
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const processView = async (e, aid, creator) => {
    if (!views.includes(aid)) {
      setViews([
        ...views,
        aid
      ]);
      let data = {
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId,
        aid: aid,
        creator: creator
      }

      const url = `${constants.services.url.api}/view/create/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      if (response.ok && responseData.status !== 'Success') {
        const index = views.indexOf(aid);
        if (index > -1) {
          const newViews = views.splice(index, 1);
          setViews(newViews);
        }
      }
    }
  }

  const keywordInsight = async (keyword) => {
    setKeywordLoading(true);

    try {
      let data = {
        tokens: 50,
        subject: keyword,
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId
      }

      const url = `${constants.services.url.api}/authenticity/ai/clue/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      setKeywordLoading(false);

      if (response.ok) {
        if (responseData.status === 'Success') {
          const statusCopy = {
            ...store.status,
            modal: {
              active: true,
              action: 'keyword',
              data: {
                title: keyword,
                description: responseData.content,
                youtube: responseData.youtube,
                news: responseData.news
              }
            }
          }
          dispatch({
            type: 'status',
            data: statusCopy
          });
        } else {
          sendMessage(constants.strings.messages('error', 'network'));
        }
      } else {
        sendMessage(constants.strings.messages('error', 'network'));
      }
    } catch (error) {
      setKeywordLoading(false);
      sendMessage(constants.strings.messages('error', 'network'));
    }
  }

  const setAssetText = (data) => {
    let responsesCopy = [...store.assets.data.responses];
    const responseIndex = responsesCopy.map(response => response.aid).indexOf(store.status.asset.aid);

    const responseDataCreator = {
      ...data,
      creator: store.profile,
      votes: [],
      reactions: []
    }

    if (responseIndex > -1) {
      responsesCopy[responseIndex].thread.push(responseDataCreator);
    }

    const storeCopy = {
      ...store,
      assets: {
        ...store.assets,
        data: {
          ...store.assets.data,
          responses: responsesCopy
        }
      },
      status: {
        ...store.status,
        asset: {
          ...store.status.asset,
          thread: [
            ...store.status.asset.thread
          ]
        }
      }
    }
    dispatch({
      type: 'store',
      data: storeCopy
    });

    bottomScroll.current?.scrollIntoView({ behavior: "smooth" });
  }

  const submitFail = () => {
    setLoading(false);
    setFailed(true);
    setTimeout(() => {
      setFailed(false);
    }, 3000);
  }

  const submitTextResponse = async () => {
    setLoading(true);
    const cleanedText = constants.utils.cleanText(textResponse);

    if (cleanedText.length) {
      try {
        let data = {
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId,
          cid: store.status.community.cid,
          gid: store.status.group.gid,
          sid: store.status.space.sid,
          description: cleanedText,
          type: 'text',
          private: store.status.asset.data.private,
          relation: {
            space: store.status.space.sid,
            response: store.status.space.sid,
            thread: store.status.asset.aid
          }
        }

        const url = `${constants.services.url.api}/asset/create/text/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        setLoading(false);

        if (response.ok && responseData.status === 'Success') {
          setSubmitted(true);
          setAssetText(responseData.data);
          setTextResponse('');
          setTimeout(() => {
            setSubmitted(false);
          }, 3000);
        }
      } catch (error) {
        submitFail();
      }
    } else {
      submitFail();
    }
  }

  return (
    <div className="ThreadView">
      <div
        className={`threadFade${props.closing ? ' closing' : ''}`}
        onClick={() => closeThread()} />
      <div className={`closeThreadWrapper${props.closing ? ' closing' : ''}`}>
        <button
          className="closeThread"
          onClick={() => closeThread()}>
          {`⇽ ${strings.default[store.language].ThreadView.Back}`}
        </button>
      </div>
      <div className={`threadModal${props.closing ? ' closing' : ''}`}>
        <div className={`media ${store.status.asset.aid}`}>
          <Video
            className="videoPlayer"
            setSrc={store.status.asset.data.location}
            setPoster={`${store.status.asset.aid}.gif`}
            onPlay={(e) => processView(e, store.status.asset.aid, store.status.asset.creator.uuid)}
            settings={store.status.settings}
            transcript={store.status.asset.data.transcript !== undefined ? store.status.asset.data.transcript : ''}
            type={store.status.asset.data.type}
            level="mainThread" />
        </div>

        <div className="dataWrapper">
          <div className="emojiWrapper">
            {(store.status.space.type === 'conversation' || store.status.space.spaceType === 'conversation') ?
              <Reaction
                aid={store.status.asset.aid}
                data={store.status.asset.reactions}
                dark /> :
              <Vote
                aid={store.status.asset.aid}
                data={store.status.asset.votes} />
            }
          </div>
          <div className="responderWrapper">
            <div className="responder">
              {store.status.asset.creator.image === undefined || store.status.asset.creator.image === '' ?
                <Participant
                  i={0}
                  participant={{
                    name: store.status.asset.creator.name
                  }}
                  display /> :
                <div
                  className="responderAvatar"
                  style={{ backgroundImage: `url(${store.status.asset.creator.image !== undefined && store.status.asset.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(store.status.asset.creator.image) : DefaultAvatar})` }} />
              }
            </div>
            <div className="metadata">
              <div className="name">
                {store.status.asset.creator.name}
              </div>
              <div className="handle">
                {store.status.asset.creator.role}
              </div>
              <div className="creation">
                {constants.utils.parseDateTime(store.status.asset.creation)}
              </div>
            </div>
          </div>
          <div className="descriptionWrapper">
            <div className="triangleUp" />
            <div className="description">
              {store.status.asset.data.description}
            </div>
            {store.status.asset.data.keywords !== undefined && store.status.asset.data.keywords.length ?
              <Keywords
                data={store.status.asset.data.keywords}
                loading={keywordLoading}
                keywordInsight={(keyword) => keywordInsight(keyword)}
                aid={store.status.asset.aid}
                dark
                update={{
                  allowed: store.status.asset.creator.uuid === store.profile.uuid,
                  assetType: 'threadMain'
                }} /> : ''
            }
          </div>
        </div>

        <div className={`responses${store.status.asset.thread.length ? '' : ' empty'}`}>
          {store.status.asset.thread.length ?
            <div className="responsesWrapper">
              {store.status.asset.thread.map((response, i) => {
                const isAIAnswer = (response.features !== undefined && response.features.followUp);

                return (
                  <div
                    className="responseWrapper"
                    key={`ResponsesWrapper${i}`}>
                    <div className={`response${response.data.type === 'text' ? ' text' : ''}`}>
                      {response.data.type === 'video' &&
                        <Video
                          className="videoPlayer"
                          setSrc={response.data.location}
                          setPoster={`${response.aid}.gif`}
                          onPlay={(e) => processView(e, response.aid, response.creator.uuid)}
                          settings={store.status.settings}
                          transcript={response.data.transcript !== undefined ? response.data.transcript : ''}
                          type={response.data.type}
                          level="thread" />
                      }
                      {(response.creator.uuid === store.profile.uuid) &&
                        <button className="deleteResponse">
                          <img
                            className="deleteIcon"
                            src={DeleteIcon}
                            onClick={(event) => props.deleteThread(response, event)}
                            alt="Delete media" />
                        </button>
                      }
                    </div>
                    <div className="data">
                      <div className="emojiWrapper">
                        {(store.status.space.type === 'conversation' || store.status.space.spaceType === 'conversation') ?
                          <Reaction
                            aid={response.aid}
                            data={response.reactions}
                            dark /> :
                          <Vote
                            aid={response.aid}
                            data={response.votes} />
                        }
                      </div>
                      <div className={`responderWrapper${isAIAnswer ? ' isAI' : ''}`}>
                        <div className="responder">
                          {(response.creator.image === undefined || response.creator.image === '') && !isAIAnswer ?
                            <Participant
                              i={0}
                              participant={{
                                name: response.creator.name
                              }}
                              display /> :
                            <div
                              className="responderAvatar"
                              style={{ backgroundImage: `url(${isAIAnswer ? DefaultAvatar : (response.creator.image !== undefined && response.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(response.creator.image) : DefaultAvatar)})` }} />
                          }
                        </div>
                        <div className="metadata">
                          <div className="name">
                            {isAIAnswer ? 'Gravity AI' : response.creator.name}
                          </div>
                          <div className="handle">
                            {isAIAnswer ? 'AI Assitant' : response.creator.role}
                          </div>
                          <div className="creation">
                            {constants.utils.parseDateTime(response.creation)}
                          </div>
                        </div>
                      </div>
                      <div className={`descriptionWrapper${isAIAnswer ? ' isAI' : ''}`}>
                        <div className={`triangleUp triangleStandard${response.data.type === 'text' ? ' text' : ''}`} />
                        <div className="triangleUp triangleUpRight" />
                        <div className={`description${response.data.type === 'text' ? ' text' : ''}`}>
                          {response.data.description}
                          {isAIAnswer &&
                          <div className="aiGenerated">
                            * {strings.default[store.language].AssetsView.AIGenerated}
                          </div>
                          }
                        </div>
                        {response.data.keywords !== undefined && response.data.keywords.length ?
                          <Keywords
                            data={response.data.keywords}
                            loading={keywordLoading}
                            keywordInsight={(keyword) => keywordInsight(keyword)}
                            aid={response.aid}
                            dark
                            update={{
                              allowed: response.creator.uuid === store.profile.uuid,
                              assetType: 'thread',
                              responseIndex: i
                            }} /> : ''
                        }
                      </div>
                    </div>
                  </div>
                )
              })}
            </div> :
            <div className="responseEmpty">
              {strings.default[store.language].AssetsView.NoResponses}
            </div>
          }
        </div>
        <div
          className="bottomScroll"
          ref={bottomScroll} />
      </div>
      <div className={`textResponse${props.closing ? ' closing' : ''}`}>
        <div className="respondWrapper">
          <button
            className={`respond${(store.status.space.magic !== undefined && store.status.space.magic) ? ' magic' : ''}`}
            onClick={() => respondThread()}>
            <span className="responseType">
              <img
                className="typeIcon"
                src={VideoEntry}
                alt="Video Entry" />
            </span>
            <span className="typeText">
              {strings.default[store.language].AssetsView.Record}
            </span>
          </button>
        </div>
        <div className="or">
          {strings.default[store.language].Upload.Or}
        </div>
        <div className="textWrapper">
          <div className="textEntryWrapper">
            <input
              type="text"
              className={`responseText ${store.language}${submitted ? ' submitted' : ''}`}
              disabled={loading || submitted || failed}
              placeholder={strings.default[store.language].ThreadView.TypeResponse}
              value={textResponse}
              onChange={(event) => setTextResponse(event.target.value)} />
          </div>
          <div className="textSubmitWrapper">
            <button
              className={`responseButton text${submitted ? ' submitted' : ''}${failed ? ' failed' : ''}`}
              disabled={!constants.utils.cleanText(textResponse).length || loading || submitted || failed}
              onClick={() => submitTextResponse()}>
              {loading ?
                <Loading active={true} /> :
                <>
                  <span className="responseType">
                    <img
                      className="typeIcon"
                      src={submitted ? GreenCheckmark : TextEntry}
                      alt="Button Entry" />
                  </span>
                  <span className="typeText">
                    {strings.default[store.language][submitted || failed ? 'ThreadView' : 'Modal'][failed ? 'failed' : `Submit${submitted ? 'ted' : ''}`]}
                  </span>
                </>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreadView;
